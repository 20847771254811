import { formatTime } from '@/filters';
import CreditiGiornalistaOverview from '@/modules/offertaFormativa/components/crediti-giornalista-overview';
import { generateKey } from '@/utils/utils';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { giornalistiCorsoService } from '../services/giornalistiCorsoService';
import { capoluoghi } from './capoluoghi';
import { iscrittoService } from '../services/iscrittoService';


const yPad = 10;
const breakTable = 100;

let doc: any = null;
let top: number = 0;
let pageNumber: number = 0;
export async function stampaAttestatoAdempienzaConTabella(
  giornalista: giornalisti.item,
  ente: ente.item,
  periodoFormativo: periodoFormativo.item,
  totaleCrediti: number,
  totaleCreditiDeontologici: number
) {
  const fileName = `attestato-adempimento-triennio-${periodoFormativo.dataInizio}-${periodoFormativo.dataFine}_${generateKey(giornalista.cognome)}_${generateKey(giornalista.nome)}.pdf`;
  doc = new jsPDF('landscape', 'mm', 'a4');
  top = 0;

  addHeader(ente.nome);

  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text(`IL CONSIGLIO REGIONALE DELL’ORDINE DEI GIORNALISTI`, 148.5, top, { align: 'center' });

  top += yPad;
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`ATTESTA CHE`, 148.5, top, { align: 'center' });

  top += yPad;
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(giornalista.nomeCompleto.toUpperCase(), 148.5, top, { align: 'center' });

  top += yPad;
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.text(`HA ASSOLTO L’OBBLIGO FORMATIVO PER IL TRIENNIO ${periodoFormativo.dataInizio}-${periodoFormativo.dataFine} `, 148.5, top, { align: 'center' });

  const corsi = await giornalistiCorsoService.ListCorsiInAnagrafica(giornalista.id, {
    ammesso: true,
    cancellato: false,
    frequentato: true
  }) ?? [];

  const iscrizioni = await iscrittoService.Giornalista(giornalista.id, {
    ammesso: true,
    cancellato: false,
    frequentato: true
  }) ?? [];

  const corsiPeriodoFormativo = corsi
    .filter(x => x.periodoFormativoId == periodoFormativo.id)
    .map(x => {
      x.creditiDisponibili = x.deontologico ? x.creditiDisponibili += 2 : x.creditiDisponibili;
      x.data = x.continuativo ? iscrizioni.find(f => f.corsoId === x.id)?.dataConfermaCrediti ?? x.data : x.data;
      return x;
    })
    .sort(function (a, b) {
      return a.data.getTime() - b.data.getTime();
    });

  if (corsiPeriodoFormativo.length > 0) {
    top += yPad;
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(`avendo frequentato i seguenti corsi:`, 148.5, top, { align: 'center' });

    top += yPad / 2;
    const head = [['Data', 'Evento', 'Crediti Conseguiti']]
    const data = corsiPeriodoFormativo.map(corso => [formatTime(corso.data, 'DD/MM/YYYY'), corso.titolo, corso.creditiDisponibili])

    if (data.length > 0) {
      autoTable(doc, {
        showHead: 'everyPage',
        styles: { overflow: "linebreak" },
        bodyStyles: { valign: "top" },
        theme: "striped",
        head: head,
        body: data,
        startY: top,
        margin: {
          top: 32,
          bottom: 32,
        },
        headStyles: {
          valign: 'middle',
          fontStyle: 'bold',
          halign: 'center',
          fillColor: [0, 159, 227]
        },
        didDrawPage: (page) => {
          pageNumber = page.pageNumber
          addFooter(ente.nome)
          addHeader(ente.nome)
          top = page.cursor.y;
        }
      });
    }
  }

  top += yPad / 2;
  doc.setFontSize(10);
  doc.setFont("helvetica", "normal");
  doc.text(`Totale Crediti: ${totaleCrediti} (${totaleCreditiDeontologici} deontologici )`, 15.5, top);

  doc.save(fileName);
}
export async function stampaAttestatoAdempienza(
  giornalista: giornalisti.item,
  ente: ente.item,
  periodoFormativo: periodoFormativo.item,
  totaleCrediti: number,
  totaleCreditiDeontologici: number
) {
  const fileName = `attestato-adempimento-triennio-${periodoFormativo.dataInizio}-${periodoFormativo.dataFine}_${generateKey(giornalista.cognome)}_${generateKey(giornalista.nome)}.pdf`;
  doc = new jsPDF('landscape', 'mm', 'a4');
  top = 0;

  addHeader(ente.nome);
  top += yPad * 2
  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text(`IL CONSIGLIO REGIONALE DELL’ORDINE DEI GIORNALISTI`, 148.5, top, { align: 'center' });

  top += yPad;
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`ATTESTA CHE`, 148.5, top, { align: 'center' });

  top += yPad;
  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text(giornalista.nomeCompleto.toUpperCase(), 148.5, top, { align: 'center' });

  top += yPad * 3;
  doc.setFontSize(18);
  doc.setFont("helvetica", "normal");
  doc.text(`HA ASSOLTO L’OBBLIGO FORMATIVO PER IL TRIENNIO ${periodoFormativo.dataInizio}-${periodoFormativo.dataFine} `, 148.5, top, { align: 'center' });
  addFooter(ente.nome)
  doc.save(fileName);
}
function addHeader(nomeEnte: string) {
  top = 0;
  top += yPad;
  var imageProperties = doc.getImageProperties(`/assets/logoRegioni/${nomeEnte}.jpg`)
  doc.addImage(`/assets/logoRegioni/${nomeEnte}.jpg`, imageProperties.fileType, 150 - imageProperties.width / 8, top, imageProperties.width / 4, imageProperties.height / 4);
  top += yPad * 3;
}

function addFooter(nomeEnte: string) {
  top = 180;
  top += yPad;
  doc.setFontSize(15);
  doc.setFont("helvetica", "italic");
  doc.text(`${capoluoghi.find(x => x.ordine === nomeEnte).capoluogo}, il ${formatTime(new Date(), 'DD/MM/YYYY')}`, 15.5, top);

  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  doc.text(`Il Presidente`, 200.5, 150 + yPad);
  if (pageNumber)
    doc.text(`Pagina ${pageNumber}`, 266.5, top);

  top += yPad / 2;
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.text("Il presente attestato ha valore per gli iscritti all'Albo dei giornalisti e non può essere prodotto agli organi della pubblica amministrazione o gestori di pubblici servizi Art. 40 Co. 1 DPR 445/2000", 148.5, top, { align: 'center' });
}
