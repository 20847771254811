import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from '@/services/_base/debounce';
import { creditiService } from '../services/creditiService';
import { periodoFormativoService } from '../services/periodoFormativoService';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { formatTime } from '@/filters';
import { store } from '@/store';
import { esenzioniService } from '../services/esenzioniService';
import { stampaAttestatoAdempienza } from '@/modules/giornalisti/utils/exportAdempienza'
import { enteService } from '@/modules/enti/service/enteService';
import settings from "@/settings";
@Component({
  components: {}
})
export default class CreditiGiornalistaOverview extends Vue {
  showEsenzione: boolean = false;

  @Prop({ required: true })
  anagraficaGiornalistaId: string;

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  query: crediti.queryParams = {
    page: 1,
    take: 20,
    periodoFormativoId: null,
    anagraficaGiornalistaId: null,
    anno: null,
  };

  periodiFormativi: periodoFormativo.item[] = [];
  list: crediti.item[] = [];
  anagrafica: giornalisti.item = {
    id: '',
    state: '',
    cognome: '',
    nome: '',
    nomeCompleto: '',
    dataNascita: null,
    comuneNascita: '',
    codiceFiscale: '',
    mail: '',
    pec: '',
    telefono: '',
    tessera: '',
    dataPrimaIscrizione: null,
    categoria: '',
    dataIscrizioneCategoria: '',
    provvedimentiDisciplinari: '',
    dataProvvedimento: '',
    dataInizioRinuncia: '',
    dataFineRinuncia: '',
    dataInizioCancellazione: '',
    dataFineCancellazione: '',
    dataPensione: '',
    dataDecesso: '',
    dataIscrizioneRegione: '',
    indirizzo: '',
    cap: '',
    comune: '',
    provincia: '',
    nazione: '',
    idCaricamentoRecord: 0,
    idEsitoRecord: 0,
    descrizioneEsitoRecord: '',
    idCaricamentoFile: 0,
    idEsitoFile: 0,
    descrizioneEsitoFile: '',
    idRegioneFile: 0,
    nota: '',
    dataCaricamento: '',
    anagraficaEnteId: '',
    adempiente: 0,
    contatoreBlacklist: 0
  };
  loading: boolean = false;
  activeTab: string = null;

  obFormativi: esenzioni.obiettivoFormativo[] = [];
  tipiEsenzioni: esenzioni.itemTipi[] = [];
  esenzioni: esenzioni.itemEsenzioni[] = [];
  esenzioniFocusTriennio: esenzioni.itemEsenzioni[] = [];

  async created() {
    this.loading = true;
    this.anagrafica = await anagraficaService.Detail(this.anagraficaGiornalistaId);
    await this.fetchObiettiviFormativi();
    const pfs = store.getters.periodiFormativi.list();
    if (this.anagrafica.dataPrimaIscrizione.getFullYear()) {
      this.periodiFormativi = pfs.filter(f => f.stato === 'chiuso' || f.stato === 'prorogato' || f.stato === 'corrente')
        .filter(f => f.dataInizio >= this.anagrafica.dataPrimaIscrizione.getFullYear() || (f.dataInizio <= this.anagrafica.dataPrimaIscrizione.getFullYear() && f.dataFine >= this.anagrafica.dataPrimaIscrizione.getFullYear()))
        .sort((a, b) => (b.dataFine + b.dataInizio) - (a.dataFine + a.dataInizio));
    }
    this.activeTab = this.periodiFormativi[0].id
    this.query.anagraficaGiornalistaId = this.anagraficaGiornalistaId;
    await this.fetchData();
  }

  get totaleEsenzione() {
    if (this.esenzioni.length <= 0 || !this.activeTab) return 0;
    const res = this.esenzioniFocusTriennio.map(a => a.qtaDeontologici + a.qtaNormali).reduce((a, b) => a + b, 0);
    return res;
  }

  get totaleEsenzioneDeontologici() {
    if (this.esenzioni.length <= 0 || !this.activeTab) return 0;
    const res = this.esenzioniFocusTriennio.reduce((a, b) => a + b.qtaDeontologici, 0);
    return res;
  }

  get obiettivoFormativoResiduo(): { totale: number, deontologici: number } {
    let obiettivoFormativoResiduo = Object.assign({}, this.obiettivoFormativo);
    if (!this.anagrafica || !this.activeTab) return obiettivoFormativoResiduo;
    const totale = obiettivoFormativoResiduo.totale - this.totaleEsenzione;
    obiettivoFormativoResiduo.totale = Math.max(totale, 0);

    const totaleDeontologici = obiettivoFormativoResiduo.deontologici - this.totaleEsenzioneDeontologici;
    obiettivoFormativoResiduo.deontologici = totaleDeontologici >= 0 ? totaleDeontologici : 0;
    return obiettivoFormativoResiduo;
  }

  get obiettivoFormativo(): { totale: number, deontologici: number, periodoFormativoId: string } {
    if (this.obFormativi.length == 0 || this.periodoFormativo == null) {
      return null
    }
    let obiettivoFormativo: esenzioni.obiettivoFormativo = {
      periodoFormativoId: this.obFormativi.filter(a => a.periodoFormativoId == this.periodoFormativo.id)[0].periodoFormativoId,
      deontologici: settings.appSetting.obiettivoFormativo.default.deontologici,
      totale: settings.appSetting.obiettivoFormativo.default.totale
    };
    this.esenzioniFocusTriennio = this.esenzioni.filter(a => a.periodoFormativoId == this.periodoFormativo.id && a.removedBy == null && a.disabled == false);
    return obiettivoFormativo;
  }

  get esenzioniAutomatiche() {
    if (!this.anagrafica || !this.activeTab) return null;
    let check = this.esenzioniFocusTriennio.filter(eft => eft.automatico);
    if (check.filter(c => c.macrotipo === 2).length != 0) {
      return "anni30daPrimaIscrizione";
    }
    if (check.filter(c => c.macrotipo === 3).length != 0) {
      return "pensione";
    }
    return null;
  }

  get dataPrimaIscrizione() {
    if (this.anagrafica && this.anagrafica.dataPrimaIscrizione)
      return formatTime(this.anagrafica.dataPrimaIscrizione, 'DD/MM/YYYY')
    return null;
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await this.fetchData();
  }, 400);

  async fetchData() {
    try {
      this.loading = true;
      this.pagination.total = await creditiService.Count(this.query);
      const rows = await creditiService.List(this.query);
      this.list = rows.map((row: any) => {
        row.totaleCreditiNonDeontologici = ((row.crediti || 0));
        row.totaleCreditiDeontologici = ((row.deontologici || 0));
        row.totaleCrediti = row.totaleCreditiNonDeontologici + row.totaleCreditiDeontologici;
        return row;
      })
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.$message({
        message: 'Errore durante il recupero dei Piani Offerta Formativi',
        duration: 5 * 1000,
        center: true,
        type: 'error'
      });
    }
  }

  async fetchObiettiviFormativi() {
    this.tipiEsenzioni = await esenzioniService.ListTipi();
    const res: esenzioni.obiettiviFormativi = await esenzioniService.GetObiettiviFormativi(this.anagraficaGiornalistaId);
    this.obFormativi = res.obFormativi;
    this.esenzioni = res.esenzioni;
    delete (res.esenzioni);
    delete (res.obFormativi);
    this.esenzioni.map(e => {
      e.automatico = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].automatico;
      e.qtaDeontologici = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].quantitaDeontologici;
      e.qtaNormali = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].quantitaNormali;
      e.macrotipo = this.tipiEsenzioni.filter(te => te.id == e.tipoEsenzioneId)[0].macroTipo;
    })
  }

  @Watch('query.periodoFormativoId')
  async queryChanged(n, o) {
    if (!n) {
      this.list = [];
      this.pagination.total = 0;
    } else {
      if (n !== o) {
        this.fetchData();
      }
    }
  }

  get totaleCrediti() {
    if (this.list.length <= 0 || !this.activeTab) return 0;
    let rowTotaliPeriodoFormativo = this.list.find(f => f.anno === null && f.periodoFormativoId === this.activeTab);
    return (rowTotaliPeriodoFormativo?.crediti || 0) + (rowTotaliPeriodoFormativo?.deontologici || 0)
  }

  get totaleCreditiDeontologici() {
    if (this.list.length <= 0 || !this.activeTab) return 0;
    const rowTotaliPeriodoFormativo = this.list.find(f => f.anno === null && f.periodoFormativoId === this.activeTab);
    return (rowTotaliPeriodoFormativo?.deontologici || 0)
  }

  get isAdempiente() {
    if (!this.obiettivoFormativo) return false;
    return (this.totaleCrediti >= this.obiettivoFormativoResiduo.totale && this.totaleCreditiDeontologici >= this.obiettivoFormativoResiduo.deontologici);
  }

  get periodoFormativo() {
    if (!this.periodiFormativi || this.periodiFormativi.length <= 0 || !this.activeTab)
      return null;
    return this.periodiFormativi.find(f => f.id === this.activeTab);
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  onEsenzioneChange(esenzione: crediti.item) {
    this.fetchData();
    this.showEsenzione = false;
    const periodoFormativo = this.periodiFormativi.find(f => f.id === esenzione.periodoFormativoId);
    if ((periodoFormativo.stato === 'chiuso' || periodoFormativo.stato === 'prorogato') && this.$can('CREDITI_GIORNALISTA.ricalcolaAdempienzePeriodoFormativo')) {
      this.$msgbox({
        title: "Ricalcolo dell'adempienza",
        message: `Aggiornare il <i>flag</i> dell'adempienza per il giornalista ${this.anagrafica.nomeCompleto} per il periodo formativo ${periodoFormativo.dataInizio}-${periodoFormativo.dataFine} per cui si è aggiunta una nuova esenzione?`,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        type: "info",
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          await this.ricalcolaAdempienza(esenzione.periodoFormativoId)
        })
        .catch(() => { });
    }
  }

  ricalcolaAdempienzaLoading: boolean = false;
  async ricalcolaAdempienza(periodoFormativoId) {
    if (this.$can('CREDITI_GIORNALISTA.ricalcolaAdempienzaGiornalista')) {
      const periodoFormativo = await periodoFormativoService.Detail(periodoFormativoId);
      this.$msgbox({
        title: "Attenzione",
        message: `L'operazione di aggiornamento del <i>flag</i> adempiente per il giornalista iscritto a https://formazionegiornalisti.it per il periodo formativo ${periodoFormativo.dataInizio}-${periodoFormativo.dataFine} è una <strong>operazione non reversibile</strong>!. Continuare?`,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "Annulla",
        type: "warning",
        dangerouslyUseHTMLString: true
      })
        .then(action => {
          this.ricalcolaAdempienzaLoading = true;
          creditiService.RicalcoloAdempienza(periodoFormativoId, this.anagraficaGiornalistaId).then(() => {
            this.ricalcolaAdempienzaLoading = false;
          }).catch(() => {
            this.ricalcolaAdempienzaLoading = false;
          });
        })
        .catch(() => { });
    }
  }

  attestatoLoading: boolean = false;
  async attestatoAdempienza() {
    this.attestatoLoading = true;
    const ente = await enteService.Detail(this.anagrafica.anagraficaEnteId);
    await stampaAttestatoAdempienza(this.anagrafica, ente, this.periodoFormativo, this.totaleCrediti, this.totaleCreditiDeontologici);
    this.attestatoLoading = false;
  }
}