import { Component, Prop, Vue } from 'vue-property-decorator';
import { creditiSpecialiService } from '../../services/creditiSpecialiService';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { giornalistiCorsoService } from '@/modules/giornalisti/services/giornalistiCorsoService';
import { giornalistiCreditiSpecialiService } from '@/modules/giornalisti/services/giornalistiCreditiSpecialiService';

@Component({})
export default class CreditiSpecialiOverview extends Vue {
  @Prop({ required: true })
  anagraficaGiornalistaId: string

  @Prop({ required: false })
  periodoFormativoId: string;

  loading: boolean = false;
  listCS: creditiSpeciali.item[] = [];

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [5, 10, 20, 50],
    total: 0
  };

  query: creditiSpeciali.queryParams = {
    periodoFormativoId: null,
    stato: "approvato",
    dataProposizione: null,
    tipo: null,
    anagraficaGiornalistaId: null,
    referenteId: null,
    referenteEnteId: null,
    page: 1,
    take: 7,
  };

  async created() {
    this.query.anagraficaGiornalistaId = this.anagraficaGiornalistaId;
    this.query.periodoFormativoId = this.periodoFormativoId || null;
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    this.pagination.total = await giornalistiCreditiSpecialiService.Count(this.anagraficaGiornalistaId, this.query);
    this.listCS = await giornalistiCreditiSpecialiService.List(this.anagraficaGiornalistaId, this.query);
    this.loading = false;
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }
}