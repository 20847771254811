import { render, staticRenderFns } from "./PartecipanteAiCorsi.vue?vue&type=template&id=3b6b49a2&scoped=true"
import script from "./PartecipanteAiCorsi.ts?vue&type=script&lang=ts&external"
export * from "./PartecipanteAiCorsi.ts?vue&type=script&lang=ts&external"
import style0 from "./PartecipanteAiCorsi.vue?vue&type=style&index=0&id=3b6b49a2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b6b49a2",
  null
  
)

export default component.exports