import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class EsenzioniService extends baseRestService {
    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async ListEsenzioniGiornalista(params?: esenzioni.listQueryParams): Promise<esenzioni.itemEsenzioni[]> {
        return await this.Get("/api/esenzioni-giornalista/list", params);
    }
    
    async ListTipi(): Promise<esenzioni.itemTipi[]> {
        return await this.Get("/api/esenzioni-giornalista/tipi-esenzioni");
    }

    async GetEsenzioni(params: esenzioni.queryParams): Promise<esenzioni.itemEsenzioni>{
        return await this.Get("/api/esenzioni-giornalista", params);
    }

    async GetObiettiviFormativi(anagraficaGiornalistaId: string): Promise<esenzioni.obiettiviFormativi>{
        return await this.Get("/api/esenzioni-giornalista/obiettivo-formativo", {anagraficaGiornalistaId});
    }

    async AddManuali(params: esenzioni.addManuali): Promise<esenzioni.risultatiAddManuali>{
        return await this.Post("/api/esenzioni-giornalista", params)
    }

    async AddMassive(params: esenzioni.addManuali): Promise<esenzioni.risultatiAddManuali>{
        return await this.Post("/api/esenzioni-giornalista/massive", params)
    }

    async ToggleEsenzione(params: esenzioni.toggleCommand): Promise<esenzioni.itemEsenzioni>{
        return await this.Put("/api/esenzioni-giornalista/toggle", params)

    }
    async RemoveEsenzione(params: esenzioni.removeCommand): Promise<esenzioni.itemEsenzioni>{
        return await this.Put("/api/esenzioni-giornalista/rimuovi-esenzione", params)
    }
}

export const esenzioniService = new EsenzioniService();