import { Component, Prop, Vue } from 'vue-property-decorator';
import { giornalistiCorsoService } from '../../services/giornalistiCorsoService';
import { capitalizeFirstLetter, generateKey } from '@/utils/utils';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';

import { ElTable } from 'element-ui/types/table';

import { enteService } from '@/modules/enti/service/enteService';
import { stampaAttestatoPresenza } from '../../utils/exportPresenza';
import { iscrittoService } from '../../services/iscrittoService';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { formatTime } from '@/filters';
import { store } from '@/store';

@Component({
  components: {}
})
export default class CorsiPartecipanteViewGiornalista extends Vue {
  @Prop({ required: true })
  anagraficaGiornalistaId: string;

  giornalista: giornalisti.item = null;
  list: giornalista.corso.item[] = [];

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [5, 10, 20, 50],
    total: 0
  };

  query: giornalista.corso.queryParams = {
    search: null,
    referenteId: null,
    enteId: null,
    deontologico: null,
    gratuito: null,
    continuativo: null,
    virtuale: null,
    ammesso: true,
    cancellato: false,
    frequentato: true,
    orderField: 'DataConfermaCrediti',
    orderDescending: true,
    page: 1,
    take: 7,
  };
  ente: ente.item;
  iscrizioni: iscritto.item[] = [];
  periodiFormativi: periodoFormativo.item[] = [];

  async created() {
    this.loading = true;
    this.giornalista = await anagraficaService.Detail(this.anagraficaGiornalistaId);
    this.ente = await enteService.Detail(this.giornalista.anagraficaEnteId);
    const pfs = store.getters.periodiFormativi.list();
    this.periodiFormativi = pfs.filter(f => f.stato === 'chiuso' || f.stato === 'prorogato' || f.stato === 'corrente').sort((a, b) => (b.dataFine + b.dataInizio) - (a.dataFine + a.dataInizio));
    await this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await giornalistiCorsoService.CountCorsiInAnagrafica(this.giornalista.id, this.query);
    var result = await giornalistiCorsoService.ListCorsiInAnagrafica(this.giornalista.id, this.query);
    this.list = result.map(m => {
      m["periodoFormativo"] = this.periodiFormativi.find(f => f.id === m.periodoFormativoId)
      return m;
    })
    this.iscrizioni = await iscrittoService.Giornalista(this.anagraficaGiornalistaId);
    this.loading = false;
  }

  formatTime(value, option)
  { return formatTime(value, option)}

   iscritto(corsoId: string) : iscritto.item {
    return this.iscrizioni.find(f => f.corsoId === corsoId);
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  onFilterChange(query) {
    this.query = query;
    this.fetchData();
  }

  onFilterClean(query) {
    this.query = query;
    this.fetchData();
  }

  async printCertificato(row: giornalista.corso.item | corso.item) {
    const iscritto = await iscrittoService.Detail(row.id, this.giornalista.id);
    stampaAttestatoPresenza(row, this.giornalista, this.ente, iscritto);
  }

  refreshTableForEndingPrintingLoading() {
    this.$nextTick(() => {
      const table = (this.$refs.partecipantiAiCorsi as ElTable);
      const childrenRefs = table.$children
      childrenRefs.forEach(child => {
        child.$forceUpdate();
      })
      if (table) {
        table.doLayout();
        table.$forceUpdate();
      }
    })
  }
}