import { formatTime } from '@/filters';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { corsiService } from '@/modules/corsi/services/corsiService';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ViewAbsences'
})
export default class extends Vue {
  @Prop({ required: false, default: null })
  user: user.item;
  @Prop({ required: false, default: null })
  giornalista: giornalisti.item;

  giornalistaLocal: giornalisti.item = null;
  assenze: { corso: corso.item, dataAssenza: Date }[] = []
  async mounted() {
    if (this.giornalista == null || this.giornalista == undefined) {
      this.giornalistaLocal = await anagraficaService.Detail(this.user.anagraficaGiornalistaId);
    }
    else {
      this.giornalistaLocal = this.giornalista
    }

    this.assenze = []
    if (this.giornalistaLocal != null && this.giornalistaLocal?.storicoBlacklist.length > 0) {
      for (var corso of this.giornalistaLocal?.storicoBlacklist) {
        this.assenze.push({
          corso: await corsiService.Detail(corso.corsoId),
          dataAssenza: corso.data,
        });
      }
    }
  }
  async fetchData() {
    if (this.giornalista == null || this.giornalista == undefined) {
      this.giornalistaLocal = await anagraficaService.Detail(this.user.anagraficaGiornalistaId);
    }
    else {
      this.giornalistaLocal = this.giornalista
    }
    this.assenze = []
    if (this.giornalistaLocal != null && this.giornalistaLocal?.storicoBlacklist.length > 0) {
      for (var corso of this.giornalistaLocal?.storicoBlacklist) {
        var assenza = {
          corso: null,
          dataAssenza: null,
        }
        assenza.corso = await corsiService.Detail(corso.corsoId);
        assenza.dataAssenza = corso.data
        this.assenze.push(assenza)
      }
    }
  }
  get dataIngressoBlackList() {
    if (this.giornalistaLocal != null)
      return this.giornalistaLocal.dataIngressoBlacklist
    return null
  }
  get dataUscitaBlackList() {
    return new Date(this.dataIngressoBlackList?.getTime() + 90 * 24 * 60 * 60 * 1000)
  }
  removeFromBlacklist() {
    this.$alert(`Attenzione, stai per rimuovere il giornalista ${this.giornalista.nomeCompleto} dalla black list<br/>`, `Cancellazione giornalista da blacklist`, {
      confirmButtonText: 'Ok',
      cancelButtonText: 'Annulla',
      dangerouslyUseHTMLString: true,
    }).then(() => {
      anagraficaService
        .RemoveFromBlacklist(this.giornalista.id)
        .then((response) => {
          this.giornalista = response
          this.$emit("removedGiornalista")
          this.fetchData()
        })
        .catch(error => {
          this.$message({
            message: `Si è verificato un errore nella cancellazione dalla blacklist`,
            dangerouslyUseHTMLString: true,
            type: 'error',
            duration: 7200
          });
        });
    }).catch(() => { });
  }
  formatTime(date, format): string {
    return formatTime(date, format);
  }
}
