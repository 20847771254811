import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class GiornalistiCreditiSpecialiService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => settings.baseUrl;
  }

  async Count(anagraficaGiornalistaId: string, params?: creditiSpeciali.queryParams): Promise<number> {
    return await this.Get<number>(`/api/giornalisti/crediti-speciali/count/${anagraficaGiornalistaId}`, params)
  }

  async List(anagraficaGiornalistaId: string, params?: creditiSpeciali.queryParams): Promise<creditiSpeciali.item[]> {
    return await this.Get(`/api/giornalisti/crediti-speciali/${anagraficaGiornalistaId}`, params)
  }
}

export const giornalistiCreditiSpecialiService = new GiornalistiCreditiSpecialiService()
